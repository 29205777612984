import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, mobileHero } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="95VH"
          w="100%"
          image={`url(${mobileHero}) center / cover no-repeat`}
          column
          justifyBetween
          alignCenter
        >
          <Header />
          <CFView textCenter column center w="100%" bg="rgb(0,0,0,.8)">
            <CFView column center mv="10px">
              <CFText color="white" style="font-size: 38px" raleway xBold>
                Now Taking
              </CFText>
              <CFText color="white" style="font-size: 38px" raleway xBold>
                Online Orders
              </CFText>
            </CFView>
            <CFView mb="10px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="700px"
          w="100%"
          image={`url(${hero}) center / cover no-repeat`}
          style={{ backgroundAttachment: 'fixed' }}
          zIndex={90}
          column
          justifyBetween
          alignCenter
        >
          <Header />
          <CFView row center alignCenter pv="12px" ph="45px" bg="rgb(0,0,0,.8)">
            <CFView column justifyStart>
              <CFText color="white" style={{ fontSize: 32 }} raleway bold>
                NOW TAKING ONLINE ORDERS
              </CFText>
            </CFView>
            <CFView ml="20px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
